import React from 'react'
import {bannerData} from './Data';
import '../components/style.css';
import Tilt from 'react-parallax-tilt';
export const FoodBanner = () => {
  return (
    <Tilt tiltMaxAngleY={4} tiltMaxAngleX={2} >
    <div className='mt-28 h-full w-screen text-center self-center mt-1 mb-28'>
    {/* <div data-aos="zoom-in-right">
        
        </div> */}
        <h3 className='text-red'>Best way to eat tasty food</h3>
        <h5 className='foodbanner_p text-lg'>Making a reservation at Delicious restaurant is easy and takes just a
couple of minutes.</h5>
        <div className='flex flex-wrap justify-center px-14'>
            {bannerData.map((e,index)=>{
                return(
                    
        <div className='service-card flex-1 py-16  mt-16 p-8 mx-4 bg-white justify-center text-center' key={index}>
          <a
        
        href="https://www.clover.com/online-ordering/pakwanindianrestaurant"
        target="_blank"
        rel="noreferrer"
      >
             <div className='flex justify-center mb-12'>   
            <img src={e.image} alt="image"/></div>
            <div className='text-heading mb-6'>{e.Heading}</div>
            <p>{e.content}</p>
            </a>
            </div>)
    }
    )}
    </div>
    </div>
    </Tilt>
  )
}
  