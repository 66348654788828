import React from "react";
import blog2 from "../../images/blog2.jpg";
import { GoPin } from "react-icons/go";
export const Blog4 = () => {
  return (
    <>
      <div className="my-28 container mx-auto w-2/4	mb-24">
        <img className="object-cover w-full h-48 mb-10" src={blog2} alt="image" />
        <h5>Savor the Flavors of India: 10 Mouthwatering Tandoori Dishes to Try Today"</h5>
        <p>10 Apr 2023</p>

        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Chicken Kali Mirch Kebab </h5>
            <p>The first dish on our list is the Chicken Kali Mirch Kebab. Made with tender chicken pieces that are marinated in a spicy black pepper marinade, this dish is packed with flavor and heat. Grilled to perfection, the Chicken Kali Mirch Kebab is a great choice for anyone who loves a good kick in their food. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Chicken Tikka </h5>
            <p>One of the most popular Indian dishes, the Chicken Tikka is a classic that never disappoints. Made with boneless chicken pieces that are marinated in a tangy yogurt and spice mixture, this dish is flavorful, juicy, and tender. Grilled to perfection, the Chicken Tikka is a must-try for anyone who loves Indian cuisine. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Hariyali Chicken Kebab </h5>
            <p>For those looking for a lighter, more refreshing option, the Hariyali Chicken Kebab is the perfect choice. Made with boneless chicken pieces that are marinated in a green marinade made with mint, coriander, and other herbs and spices, this dish is both flavorful and refreshing. Grilled to perfection, the Hariyali Chicken Kebab is a great choice for those who want to enjoy the flavors of Indian cuisine without the heat. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Tandoori Shrimp </h5>
            <p>If you're a seafood lover, you have to try our Tandoori Shrimp. Made with jumbo shrimp that are marinated in a blend of yogurt and spices, this dish is grilled in a tandoor oven, resulting in succulent, juicy, and flavorful shrimp. Whether you're a fan of spicy food or prefer a milder taste, the Tandoori Shrimp is a must-try dish. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Afghani Chicken </h5>
            <p>A specialty of Pashtun cuisine, the Afghani Chicken is known for its rich and creamy flavors. Made with boneless chicken pieces that are marinated in a blend of cream, ginger, and garlic, this dish is grilled to perfection, resulting in a tender and flavorful dish that's sure to please. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Tandoori Murgh </h5>
            <p>Another classic Indian dish, the Tandoori Murgh is a must-try for any fan of Indian cuisine. Made with chicken that's marinated in a blend of yogurt and spices, this dish is grilled in a tandoor oven, resulting in a juicy, tender, and packed-with-flavor dish that's hard to resist. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Lamb Seekh Kebab </h5>
            <p>For those who love lamb, the Lamb Seekh Kebab is a must-try dish. Made with minced lamb meat that's mixed with spices and grilled on skewers, this dish is full of flavor and perfect for any meat lover. Juicy and tender, the Lamb Seekh Kebab is a dish that you won't forget anytime soon. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Tandoori Fish </h5>
            <p>If you're a seafood lover, you'll love our Tandoori Fish. Made with fresh fish that's marinated in a blend of yogurt and spices, this dish is grilled in a tandoor oven, resulting in a tender, flaky, and packed-with-flavor dish that's perfect for any occasion. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Mix Tandoori Platter </h5>
            <p>The Mix Tandoori Platter is perfect for those who want to try a variety of tandoori dishes without having to commit to just one. It's a great option for sharing with friends or family, or for trying something new if you're not sure what to order. Plus, with its generous portion size, it's sure to satisfy even the heartiest of appetites. </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-1">
            <GoPin />
          </div>
          <div>
            <h5>Tandoori Fish Tikka </h5>
            <p>The Tandoori Fish Tikka is a popular item on our menu, and for good reason. The fish is always fresh and the marinade is carefully crafted to ensure a perfect balance of flavors. It's a great option for those who want to enjoy a healthy and delicious meal without compromising on taste. </p>
          </div>
        </div>
       
      </div>
    </>
  );
};
