import React from "react";
import { Banner } from "../../components/Banner";
import blog_img from "../../images/blog-img.jpg";
import { Link } from "react-router-dom";
import blog2 from "../../images/blog2.jpg";
import blog3 from "../../images/blog_3.jpg";
import blog4 from "../../images/blog_4.jpg";


export default function Blogs() {
  return (
    <>
      <Banner heading={"Blogs"} para={"Welcome to our blog"} />
      <div class="container mx-auto mt-12 mb-24 pb-10 flex flex-wrap">
        <div className="relative bg-white w-full rounded-lg mx-5 shadow-md lg:max-w-sm">
          <img className="object-cover w-full h-48" src={blog_img} alt="image" />
          <div className="p-4">
            <h4 className="text-xl font-semibold tracking-tight">Craving Indian Pakwan? Top Indian Restaurant Near Colorado Springs To Satisfy Your Craving</h4>
            <p className="mb-2 leading-normal">Furthermore, merely talking about these dishes can cause a water blast in the mouth for someone living overseas. Thanks to Pakwan Indian restaurant colorado springs, we are still saved! So take a look at Pawan Indian restaurant and bar colorado springs menu choice that every Indian in the area is grateful for.</p>
            <Link to="/blog/1">
              <button className="absolute bottom-5 right-5 px-4 py-2 text-sm border border-sky-700 rounded shadow">Read more</button>
            </Link>
          </div>
        </div>

        {/* blog 2 */}

        <div className="relative bg-white w-full mx-5 pb-10 rounded-lg shadow-md lg:max-w-sm">
          <img className="object-cover w-full h-48" src={blog2} alt="image" />
          <div className="p-4">
            <h4 className="text-xl font-semibold tracking-tight">Discover the Authentic Flavors of India at Pakwan</h4>
            <p className="mb-2 leading-normal">The Best Indian Restaurant in Colorado Springs.Discover the Authentic Flavors of India at Pakwan: The Best Indian Restaurant in Colorado Springs.You won't want to miss the opportunity to experience the delicious and authentic flavors of Pakwan Restaurant. From its vast menu of Indian dishes to its warm and inviting atmosphere, Pakwan has quickly become a local favorite.</p>
            <Link to="/blog/2">
              <button className="absolute bottom-5 right-5 px-4 py-2 text-sm border border-sky-700 rounded shadow">Read more</button>
            </Link>
          </div>
        </div>

        {/* blog 3 */}

        <div className="relative bg-white w-full mx-5 pb-10 rounded-lg shadow-md lg:max-w-sm">
          <img className="object-cover w-full h-48" src={blog3} alt="image" />
          <div className="p-4">
            <h4 className="text-xl font-semibold tracking-tight">How to Satisfy Your Cravings with 12 Mouthwatering Indian Breads at Pakwan Restaurant and Bar</h4>
            <p className="mb-2 leading-normal">At Pakwan Restaurant and Bar, we take pride in offering a wide variety of authentic Indian dishes, and our selection of bread is no exception. From classic naan bread to unique and flavorful options, we have something for everyone. In this blog post, we'll introduce you to our 12 different types of Indian bread and give you a glimpse into the delicious flavors that await you at our restaurant.</p>
            <Link to="/blog/3">
              <button className="absolute bottom-5 right-5 px-4 py-2 text-sm border border-sky-700 rounded shadow">Read more</button>
            </Link>
          </div>
        </div>

        {/* blog 4 */}

        <div className="relative bg-white w-full mx-5 pb-10 my-10 rounded-lg shadow-md lg:max-w-sm">
          <img className="object-cover w-full h-48" src={blog4} alt="image" />
          <div className="p-4">
            <h4 className="text-xl font-semibold tracking-tight">Savor the Flavors of India: 10 Mouthwatering Tandoori Dishes to Try Today"</h4>
            <h4 className="mt-4">Chicken Kali Mirch Kebab</h4>
            <p className="mb-2 leading-normal">The first dish on our list is the Chicken Kali Mirch Kebab. Made with tender chicken pieces that are marinated in a spicy black pepper marinade, this dish is packed with flavor and heat. Grilled to perfection, the Chicken Kali Mirch Kebab is a great choice for anyone who loves a good kick in their food.</p>
            <Link to="/blog/4">
              <button className="absolute bottom-5 right-5 px-4 py-2 text-sm border border-sky-700 rounded shadow">Read more</button>
            </Link>
          </div>
        </div>

      </div>
    </>
  );
}
