import React, { useEffect, useState, useLayoutEffect } from "react";
import { AiOutlineWhatsApp, AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { BsTelephoneFill, BsTwitter } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import "../components/style.css";
import { Button } from "./Button";
import logo from '../images/logo.png';
export const Header = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setSmall(window.pageYOffset > 200));
    }
  }, []);

  return (
    <nav className="navbar">
   
      
      <div className={small ? "header_color px-0" : "header_color"}>
        <div className="flex flex-wrap container mx-auto sm:py-1">
          <div className="w-full">
            <nav className="relative flex flex-wrap items-center justify-between py-0">
              <div className="nav-wrapper w-full flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between h-12 lg:w-auto px-4 lg:static lg:block lg:justify-start">
                  <span className="font-bold leading-relaxed inline-block mr-4 py-1 whitespace-nowrap uppercase">
                    <Link to="/">
                      <img
                        style={{ position: "absolute", top: -22, width: "120px", objectFit: "cover" }}
                        src={logo}
                        alt="logo"
                      />
                    </Link>
                  </span>
                  <button className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" onClick={() => setMenuOpen(!menuOpen)}>
                    {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
                  </button>
                </div>
                <div className={"lg:flex flex-grow items-center justify-around ml-48" + (menuOpen ? "h-screen flex" : " hidden")} id="example-navbar-info">
                  <ul className="flex flex-col lg:flex-row list-none">
                    <li className="nav-item">
                      <Link to="/">
                        <span className="px-3 text-base py-2 flex items-center font-bold leading-snug hover:text-orange-500">Home</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about">
                        <span className="px-3 py-2 flex items-center font-bold leading-snug hover:text-orange-500 ">About</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/gallery">
                        <span className="px-3 py-2 flex items-center font-bold leading-snug hover:text-orange-500">Gallery</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/speciality">
                        <span className="px-3 py-2 flex items-center font-bold leading-snug hover:text-orange-500">Menu</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/blog">
                        <span className="px-3 py-2 flex items-center font-bold leading-snug hover:text-orange-500">Blogs</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact">
                        <span className="px-3 py-2 flex items-center font-bold leading-snug hover:text-orange-500">Contact</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a href="https://www.clover.com/online-ordering/pakwanindianrestaurant" target="_blank">
                        <span
                          className="px-3 py-0 flex items-center bg-orange-500
border border-orange-500 lg:hidden font-bold leading-snug hover:text-orange-500"
                        >
                          Order Now
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sm:pl-24">
                <a href="https://www.clover.com/online-ordering/pakwanindianrestaurant" target="_blank">
                  <button class="order-button hover:bg-orange-600 bg-orange-500 text-white rounded-full font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-orange-600 sm:ml-4">
                   
                      
                      Order Now
                  
                  </button>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </nav>
  );
};
