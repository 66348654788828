import React from 'react'
import blog2 from "../../images/blog2.jpg";
import { GoPin } from "react-icons/go";
export const Blog3 = () => {
  return (
   <>
 <div className="my-28 container mx-auto w-2/4	mb-24">
        <img className="object-cover w-full h-48 mb-10" src={blog2} alt="image" />
        <h5>How to Satisfy Your Cravings with 12 Mouthwatering Indian Breads at Pakwan Restaurant and Bar</h5>
        <p>5 Apr 2023</p>
        <div className="flex">
        <div className="mr-1">
        <GoPin/>
        </div>
        <div>
         <p>At Pakwan Restaurant and Bar, we take pride in offering a wide variety of authentic Indian dishes, and our selection of bread is no exception. From classic naan bread to unique and flavorful options, we have something for everyone. In this blog post, we'll introduce you to our 12 different types of Indian bread and give you a glimpse into the delicious flavors that await you at our restaurant. </p>
         </div>
        </div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/>
        </div>
        <div>
        <p>Garlic Naan: This popular bread is made with a special naan dough that's been brushed with garlic and butter before cooking it in a tandoor oven. The result is a soft, chewy bread with a savory garlic flavor. The aroma of freshly baked garlic naan is irresistible and it's a great accompaniment to any Indian meal.</p>
        </div>
        </div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>Butter Naan: Butter naan is a rich and indulgent bread that's made with a special naan dough and generous amounts of butter. It has a soft, fluffy texture and a buttery flavor that pairs perfectly with spicy curries and gravies. It's also great as a standalone snack, especially when served hot and fresh. </p></div></div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>Plain Naan: This classic naan bread is made with a simple naan dough that's cooked in a tandoor oven until it's soft and fluffy. It's a versatile bread that pairs well with just about any Indian dish. Plain naan has a mild flavor and a slightly chewy texture, making it the perfect canvas for showcasing the flavors of the accompanying dish. </p></div></div>
        <div className="flex">
            
        <div className="mr-1">
        <GoPin/></div><div><p>Bullet Naan: Bullet naan is a spicier version of garlic naan, made by adding chopped green chilies to the garlic-butter mixture. The result is a flavorful and spicy bread that's perfect for those who love a bit of heat. Bullet naan is great as a standalone snack or as an accompaniment to spicy curries. </p></div></div>
        <div className="flex">
           
        <div className="mr-1"><GoPin/></div><div><p>Cheese Naan: Cheese naan is a unique take on the classic naan bread, made by stuffing the naan dough with a mixture of cheese and herbs before cooking it in a tandoor oven. The result is a deliciously cheesy and flavorful bread that's perfect for cheese lovers. </p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>Rosemary Naan: Rosemary naan is a fragrant and flavorful bread that's made by adding fresh rosemary to the naan dough. The rosemary adds a lovely aroma and flavor to the bread, making it a great accompaniment to roasted meats and vegetables. 
 </p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>Kashmiri Naan: Kashmiri naan is a sweet and fruity bread that's made by adding a mixture of dried fruits and nuts to the naan dough before cooking it in a tandoor oven. The result is a bread that's sweet, fragrant, and packed with flavor. It's a great accompaniment to spicy curries and gravies. </p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>Keema Naan: Keema naan is a savory bread that's stuffed with a spicy mixture of minced meat and spices. The filling is usually made with lamb or beef, but vegetarian versions are also available. Keema naan is a hearty and filling bread that's perfect for a satisfying meal. </p></div></div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/></div><div><p>Lacha Paratha: Lacha paratha is a layered flatbread that's made by rolling out the dough and folding it into layers before cooking it on a griddle. The result is a bread that's flaky and crispy on the outside and soft and chewy on the inside. Lacha paratha is a great accompaniment to curries and gravies. </p></div></div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/></div><div><p>Truffle Naan: Truffle naan is a decadent and indulgent bread that's made by adding truffle oil and grated truffle to the naan dough before cooking it in a tandoor oven. The result is a bread that's rich, flavorful, and incredibly delicious. </p></div></div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/></div><div><p>Missi Roti: Missi roti is a traditional Punjabi flatbread that's made with a mixture of wheat flour, gram flour, and spices. The dough is flavored with a blend of spices and herbs, and the bread is cooked on a griddle until it's golden. </p></div></div>
       
      </div>
      
   </>
  )
}
