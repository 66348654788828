import React from "react";
import blog_img from "../../images/blog-img.jpg";
import { GoPin } from "react-icons/go";
export const Blog1 = () => {
  return (
    <>
      <div className="my-28 container mx-auto w-2/4	mb-24">
        <img className="object-cover w-full h-48 mb-10" src={blog_img} alt="image" />
        <h5>Craving Indian Pakwan? Top Indian Restaurant Near Colorado Springs To Satisfy Your Craving</h5>
        <p>14 Dec 2022</p>
        <div className="flex">
        <div className="mr-1">
        <GoPin/>
        </div>
        <div>
         <p>Furthermore, merely talking about these dishes can cause a water blast in the mouth for someone living overseas. Thanks to Pakwan Indian restaurant colorado springs, we are still saved! So take a look at Pawan Indian restaurant and bar colorado springs menu choice that every Indian in the area is grateful for. </p>
         </div>
        </div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/>
        </div>
        <div>
        <p>Indian Dishes To Die For! Talk about globalization, and you can't help but notice how India is making inroads into putting its impact on the world. It is completely destroying the world! Check out the following meals that are popular not just in India but also in an Indian restaurant in Colorado Springs:</p>
        </div>
        </div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>Pakora is a savory Indian meal made with deep-fried vegetables such as cauliflower and eggplant. It's a typical Indian snack seen on many street corners, but it's very simple to cook at home. Pakoras are traditionally most popular in the spring when people eat fried foods to celebrate the monsoon season.</p></div></div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>These small fritters, also known as bhajias, are the ideal quick party snack. You can use any vegetable you choose, such as cauliflower or potatoes. Bengal gram flour, spices, and water are combined to make a batter that coats the veggies before frying them to a crisp, golden brown.</p></div></div>
        <div className="flex">
            
        <div className="mr-1">
        <GoPin/></div><div><p>Papadum is an Indian food made with lentil, gram, rice, or chickpea flour and baked into a thin, crispy cracker bread. It can be eaten on its own, with pickles, or with other meals like curries, where it is used as a scooping instrument.</p></div></div>
        <div className="flex">
           
        <div className="mr-1"><GoPin/></div><div><p>Papadum can also be served with chutneys or raita sauce and hot tea. With the dish's popularity, there are several varieties, such as rice papadum, which is boiled in water and dried in the sun, masala papadum, which contains spices such as black pepper, chile, cumin, and garlic, and jackfruit papadum from Karnataka, which blends jackfruit with chickpea flour.</p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>Chicken tikka is a popular Indian meal that includes marinating chicken pieces in particular spices (coriander, garam masala, ginger paste) and yogurt to tenderize them before grilling them on skewers. Individual skewers can be made, or the chicken tikka can be threaded onto large skewers and removed onto a dish for guests to pick up with toothpicks. In any case, this could be the most sought-after app at the party. </p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>Chili paneer is an appetizer that is traditionally Indo-Chinese. The meal is made up of paneer cheese cubes that have been fried and combined with a sweet, sour, and spicy sauce. The dish comes in three varieties: dry, semi-dry, and restaurant-style (in gravy).</p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>The most common form is fried paneer served in sauce. Paneer cubes are fried after being battered in a mixture of cornstarch, flour, ginger paste, garlic paste, red chili powder, salt, and black pepper. Typically, the gravy is created with ginger, garlic, chili peppers, spring onions, soy sauce, red chili powder, and sugar.</p></div></div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/></div><div><p>Gobi Manchurian is a traditional Indo-Chinese dish consisting of fried cauliflower (gobi) that's tossed in a sweet and spicy sauce (Manchurian). There are two versions of the dish – dry and in gravy. The dry version is often served as an appetizer or a bar snack with ketchup as a dipping sauce, while the gravy version features a thick sauce made with cornstarch and is usually served as a main dish with rice on the side, either fried or steamed.</p></div></div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>The cauliflower florets are coated in batter and deep-fried until crisp. The batter often consists of water, flour, corn flour, red chili powder, and seasonings. At the same time, the sauce is made with a combination of garlic, ginger, onions, scallions, soy sauce, vinegar, oil, sugar, and chili peppers. </p></div></div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>Dahi Bhalla chaat is a popular Indian snack that can be served with the main course or as an appetizer. The meal is from North India and consists of delicious lentil-based fried balls (vadas) drowned in a thick yogurt sauce (dahi).</p></div></div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/></div><div><p>This famous street meal is frequently seasoned with chile, cumin, coriander, and various chutneys. The meal is extremely popular at special occasions such as weddings and is a refreshing treat on a hot summer day.</p></div></div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>Biryani is a delicious rice dish that is both luxurious and delectable. It has an outstanding taste and is excellent for days when you want something completely out of the ordinary! It is made with mutton, rice, and an assortment of spices. Biryani is nawab because it is rich and laden with dry fruits and herbs that enhance the flavor. If you enjoy biryani instead of the usual mutton and chicken biryani, try this excellent recipe that mixes well with any raita or salad. It is appropriate for special occasions and celebrations.</p></div></div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/></div><div><p>Biryani is a mixed rice dish that originated among Muslims in the Indian subcontinent but has since become a global craze. It is made using Indian spices, rice, and usually some form of meat (chicken, cattle, goat, lamb, shrimp, fish) or, in some circumstances, no meat, and occasionally, eggs and potatoes.</p></div></div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>Traditional snacks are something we all associate with living in any part of the world. Are you missing any of these Indian starters, main courses, or desserts? Don't worry! Come dine the most popular traditional dishes in India at the best Indian restaurant in colorado springs-Pakwan.</p></div></div>
      </div>
    </>
  );
};
