import React from 'react'
import styled from "styled-components";
import { Button } from "../components/Button";
import banner from '../images/banner.jpg';
import AOS from "aos";

const Banner2 = styled.div`
  position: relative;
  // top:1.25vh;
  margin-bottom:10vh; 
  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    filter: brightness(80%);
  }
`;
const BannerContent2 = styled.div`
position: absolute;
top: 60%;
left: 50%;
transform: translate(-50%, -50%);
  }
  
  button {
    margin: 1rem auto;
    padding: 1rem 0px;
  }
  span{
    font-family: Nunito, sans-serif !important;
  }
`;

export const Banner = ({heading,para}) => {
  AOS.init({
    offset: 100,
    duration: 600,  
    easing: 'ease-in-sine',
    delay: 100, 
  });
  return (
    <div>
        <Banner2>
          <div className="brightness-50">
        <img src={banner} />
        </div>
        <BannerContent2>
        
          <span style={{fontSize: '4rem'}} className="text-center p-0 text-white">{heading}</span>
          {/* <span className="font-medium text-2xl p-0 text-white">{para}</span> */}
           

          {/* <h3 className="text-orange-600" >Pakwancos</h3> */}

          

        
        </BannerContent2>
        

      </Banner2>

    </div>
  )
}
