import React, { useEffect, useState } from "react";
import food1 from "../../images/new/main2.JPG";
import food from "../../images/new/main1.JPG";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { Product } from "../../components/products/Product";
import { Review } from "../../components/Review";
import { Mapper } from "../../components/Map";
import { Testimonial } from "../../components/testimonial/testimonial";
import { Reserve } from "../../components/reservation/Reserve";
import { FoodBanner } from "../../components/FoodBanner";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import Slider from "react-slick";
import "../../App.css";
import './style.css';


const Banner = styled.div`
  position: relative;
  height: 100vh;
  overlay_image{
      
  }
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    filter: brightness(90%);
    img {
      background: black;
    }
  
  }
`;


const BannerContent = styled.div`
  background: rgba(0, 0, 0, 0.8);
  max-height: 18rem;
  border-radius: 1rem;
  padding: 1em;
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 700px) {
    max-height: 25rem;
  }
  h3 {
    @media (max-width: 700px) {
      font-size: 2rem;
    }
  }
  button {
    margin: 1rem auto;
    padding: 1rem 0px;
  }
`;
const Banner_text = styled.span`
  text-align: left;

  span {
    position: relative;

    font-family: "Avenir Next", sans-serif;
    font-weight: 700;
    font-size: 1.35rem;
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 0.05em;
    display: inline-block;
    color: white;
  }

  span:before {
    position: absolute;
    left: 0;
    top: 0;
    content: attr(filter-content);

    filter: url(#motion-blur-filter);
  }

  svg {
    display: none;
  }
`;
const ContainerHome = styled.div`
  .field {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: auto;
    positiom: relative;
  }

  @keyframes wheel {
    to {
      opacity: 0;
      top: 60px;
    }
  }

  @-webkit-keyframes wheel {
    to {
      opacity: 0;
      top: 60px;
    }
  }

  .scroll {
    width: 60px;
    height: 60px;
    // border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    paddingtop: 5rem;
    animation: down 2.16s infinite;
    position: absolute;
    bottom: -20rem;
    -webkit-animation: down 2.16s infinite;
    &::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 18px;
      width: 18px;
      height: 18px;
      border-left: 2px solid #f7f7f7;
      border-bottom: 2px solid #f7f7f7;
      transform: rotate(-45deg);
    }
  }
  .scroll2 {
    width: 60px;
    height: 60px;
    // border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    paddingtop: 5rem;
    animation: down 2.15s infinite;
    position: absolute;
    bottom: -9rem;
    -webkit-animation: down 2.15s infinite;
    &::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 18px;
      width: 18px;
      height: 18px;
      border-left: 2px solid #f7f7f7;
      border-bottom: 2px solid #f7f7f7;
      transform: rotate(-45deg);
    }
  }
  .scroll3 {
    width: 60px;
    height: 60px;
    // border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    paddingtop: 5rem;
    animation: down 2.14s infinite;
    position: absolute;
    bottom: -8rem;
    -webkit-animation: down 2.14s infinite;
    &::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 18px;
      width: 18px;
      height: 18px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);
    }
  }

  @keyframes down {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translateY(15px);
    }
    40% {
      transform: translate(0);
    }
  }

  @-webkit-keyframes down {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translateY(15px);
    }
    40% {
      transform: translate(0);
    }
  }

  @keyframes scroll {
    0% {
      height: 40px;
    }
    30% {
      height: 70px;
    }
    60% {
      height: 40px;
    }
  }

  @-webkit-keyframes scroll {
    0% {
      height: 40px;
    }
    30% {
      height: 70px;
    }
    60% {
      height: 40px;
    }
  }
`;



export const Home = () => {
  

  const[datamsg,setDataMsg] = useState('');
  const[datamsg2,setDataMsg2] = useState('');


  useEffect(() => {
    startFunction();
    
  });

 

  
  const comment = {
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    //

    autoplaySpeed: 12000,
    // centerMode: true,
    focusOnSelect: true,
    pauseOnHover: false,
    dots: true,
  };

  const startFunction = () =>{
  const d = new Date();
  let day = d.getDay();
  console.log(day)
    if(day == 0){
      return(
      setDataMsg('Sunday 11:30 am-9:30 pm')
      )
    }
    else if(day == 1){
      return(
        setDataMsg('11:30 am-2:30 pm'),
        setDataMsg2('4:30 pm-9:30 pm')
      )
    }
    else if(day == 2){
      return(
        setDataMsg('11:30 am-2:30 pm'),
        setDataMsg2('4:30 pm-9:30 pm')
      )
    }
    else if(day == 3){
      return(
        setDataMsg('11:30 am-2:30 pm'),
        setDataMsg2('4:30 pm-9:30 pm')
      )
    }
    else if(day == 4){
      return(
        setDataMsg('11:30 am-2:30 pm'),
        setDataMsg2('4:30 pm-9:30 pm')
      )
    }
    else if(day == 5){
      return(
        setDataMsg('11:30 am-2:30 pm'),
        setDataMsg2('4:30 pm-9:30 pm')
      )
    }
    else if(day == 6){
      return(
        setDataMsg('11:30 am-9:30 pm')
      )
    }

  }

  return (
    <>
      <div>
        <Slider className="w-screen h-screen" {...comment}>
          <div>
            <Banner className="h-screen">
              <img src={food1} alt="food" style={{ backgroundSize: "contain", height: "100vh" }} />
              <BannerContent >
                <Banner_text>
                  <h2 className="text-white text-center text">Start the day with</h2>
                  <h2 className="text-orange-500 mb-8 text-center">Treat of flavours!</h2>

                  <svg xmlns="http://www.w3.org/2000/svg">
                    <filter id="motion-blur-filter" filterUnits="userSpaceOnUse">
                      <feGaussianBlur stdDeviation="100 0"></feGaussianBlur>
                    </filter>
                  </svg>
                  <h3 className="text-center text-white heading_underline">Our Today's Timings</h3>
                  <h5 className="text-2xl font-sans text-center text-white ">{datamsg}</h5>
                  <h5 className="text-2xl font-sans m-0 p-0 text-center text-white">{datamsg2}</h5>

                  {/* <span filter-content="OPEN">Open Everyday from 12 p.m. to 10 p.m</span> */}
                </Banner_text>
                <ContainerHome>
                  <div className="field" style={{ marginTop: "10rem" }}>
                    <div className="scroll"></div>
                    {/* <div className="scroll2"></div>
                    <div className="scroll3"></div> */}
                  </div>
                </ContainerHome>
              </BannerContent>
            </Banner>
          </div>
          <div className="h-screen">
            <Banner className="h-screen">
              <div className="react-slick"> 
              <div className="overlay_image">
                <img src={food} alt="food"/>
                </div>
                <BannerContent>
                  <Zoom top>
                    <Banner_text $height="20rem">
                      
                      <h2 className="text-orange-500 font-mono text-3xl text-center">Delicious Food</h2>
                      <h3 className="text-white font-mono text-3xl text-center ">is Waiting For you</h3>
                    
                      {/* <span className="open-text">Open</span> */}
                          {/* <h3>Our Timings</h3> */}
                          {/* <h3 className="font-sans text-center text-white Segoe UI bg-zinc-800">{datamsg}</h3>
                          <h3 className="font-sans text-center text-white Segoe UI bg-zinc-800">{datamsg2}</h3> */}
                          
                      
                      
                    </Banner_text>
                  </Zoom>
                  <Bounce bottom>
                  {/* <Button /> */}
                  </Bounce>
                  <ContainerHome>
                    <div className="field">
                      <div className="scroll"></div>
                      {/* <div className="scroll2"></div>
                      <div className="scroll3"></div> */}
                    </div>
                  </ContainerHome>
                </BannerContent>
              </div>
            </Banner>
          </div>
        </Slider>
        {/* <div className="sticky z-40 top-0 bg-white h-14 sm:py-6"></div> */}
        <Product />
        {/* <FoodCard/> */}
        
        {/* <Review /> */}
        
        <Reserve />
        {/* <Special/> */}
        <Testimonial />
        <FoodBanner />
        <Mapper />

        {/* <Footer2/> */}
      </div>
    </>
  );
};
