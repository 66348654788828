import React from "react";
import Viewer from "react-viewer";
import { Banner } from "../components/Banner";
import Flash from 'react-reveal/Flash';
import Fade from 'react-reveal/Fade';
import images from "./Data";
import d1 from "../images/gallery/d1.jpg";
import d2 from "../images/gallery/d2.jpg";
import d3 from "../images/gallery/d3.jpg";
import d4 from "../images/gallery/d4.jpg";
import d5 from "../images/gallery/d5.jpg";
import d6 from "../images/gallery/d6.jpg";
import d7 from "../images/gallery/d7.jpg";
import d8 from "../images/gallery/d8.jpg";
import d9 from "../images/gallery/d9.jpg";
import d10 from "../images/gallery/d10.jpg";
import d11 from "../images/gallery/d11.jpg";
import d12 from "../images/gallery/d12.jpg";
import d13 from "../images/gallery/d13.jpg";
import d14 from "../images/gallery/d14.jpg";
import d15 from "../images/gallery/d15.jpg";
import d18 from "../images/gallery/d16.jpg";
import d16 from "../images/gallery/d17.jpg";
import d17 from "../images/gallery/d18.jpg";






export const Gallery = () => {
  const fileimage = [d1,d2,d3,d4,d5,d6,d7,d8,d9,d12,d13,d14,d15,d18,d11,d16,d17,d10];
  const [visible, setVisible] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  
  return (
    <>
      
      <Banner heading={"Gallery"} />
      

      <div className="gallery_grid flex flex-wrap align-center justify-center mb-28">
        {fileimage.map((img, index) => (
                  

          <div key={index.toString()} className="mb-2 bg-contain">
                    

            <img key={img}
            style={{width: "100%",
              
              maxHeight: "100%"}}
              alt="image"
              src={img}
              // src={images}
              onClick={() => {
                setVisible("true");
                setActiveIndex(index);
              }}
            />
            
          </div>
          
        ))}
      </div>
              

      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        zoomSpeed={0.2}
        images={images}
        activeIndex={activeIndex}
        downloadable
      />
    </>
  );
};
