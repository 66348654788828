import React from "react";
import food1 from "../../images/food1.jpg";
import food3 from "../../images/food3.jpg";
import design from "../../images/blob.svg";
import Slide from 'react-reveal/Slide';
import { useMediaQuery } from "../../hooks/MediaQueryHook";



import '../about/style.css';
import { Banner } from "../../components/Banner";
export const About = () => {
  const isRowBased = useMediaQuery("(min-width: 500px)");
  const about_card={
    
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
      
  }
  return (
    <>
    <Banner heading={"About"} para={""}/>
    <Slide left>



      <div className="mb-24">
        <div style={about_card} className="flex flex-wrap container mx-auto">
          <div className={isRowBased?"basis-3/6":""}>
            <img className="h-full w-full" src={food1} alt="food" />
          </div>
          <div className={isRowBased?"basis-3/6":""}>
            <div className="p-4">
            <p className="font-normal	text-base text-justify p-8">
            <h1>Our Story</h1>
            <h1>Pakwaan</h1>
              We are grown in a culture of vibrancy in every aspect of life.
              Food has been an integral part of our brought-up. Every meal of
              the day is like a festive celebration in itself. Considering the
              fondness of Indian cuisines and with an aim to stir-up your taste
              in Indian spices and curries, we have conceptualized a fine-dining
              In décor and its complimenting ambiance shall bring curiosity
              in each one of you. Every dish on the menu is crafted to give you
              the genuine taste of Indian food cooked with the freshest of
              ingredients as made in Indian homes.
            </p>
            </div>
            <div className="p-10">
              <h1>More details coming soon ...</h1>
            </div>
          </div>
        </div>
      </div>
</Slide>
{/* <Slide right>



      <div className="box_div my-28 relative" style={{ backgroundImage: `url(${food3})`,backgroundSize:'cover'}}>
        <h3 className="text-3xl absolute top-44 left-36 z-50 text-white">Experience good food<br/>with great taste<br/> and a fine dining</h3>
       <img src={design} alt="design"/>

      </div>
</Slide> */}
<Slide left>



      {/* <div>
        <div style={about_card} className="flex flex-wrap container mb-24 mx-auto">
          <div className="basis-2/6">
            
            <p className="font-normal	text-base text-justify p-8">
              <h1>About us</h1>
            <h1>Pakwaan</h1>
            Our maxim has forever been 'The reason to have one, when you could have everything'. This is the thumb rule we comply with and it has gotten us only euphoria. Colorado is a city that is so gastronomically progressed, overflowing with new potential and an awesome culinary scene. There is simply such a great amount to investigate and encounter, that is the reason we decide to enjoy it all as opposed to agreeing to one. To fulfill our avaricious foodie soul we find the entire day eating cafés our best feasting choice. Yet, which is the best eatery in the city that suits our motivation? That' simple to reply - 'Pakwan'. Pakwan is something they are just thrilled about. Directing on opulent corner of Colorado Spring, Austin Bluffs Pkwy is functional 12pm to 10pm. Tamra astonishes you with its class and refinement.
            </p>
          </div>
          <div className="basis-4/6">
            <img className="h-full w-full bg-cover" src={food1} alt="food" />
          </div>
        </div>
      </div> */}
      </Slide>
    </>
  );
};
