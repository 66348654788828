import React from 'react'
import blog2 from "../../images/blog2.jpg";
import { GoPin } from "react-icons/go";
import { Blog3 } from './Blog3';
export const Blog2 = () => {
  return (
   <>
 <div className="my-28 container mx-auto w-2/4	mb-24">
        <img className="object-cover w-full h-48 mb-10" src={blog2} alt="image" />
        <h5>Discover the Authentic Flavors of India at Pakwan: The Best Indian Restaurant in Colorado Springs.</h5>
        <p>22 Feb 2023</p>
        <div className="flex">
        <div className="mr-1">
        <GoPin/>
        </div>
        <div>
         <p>If you're a foodie in Colorado Springs, you won't want to miss the opportunity to experience the delicious and authentic flavors of Pakwan Restaurant. From its vast menu of Indian dishes to its warm and inviting atmosphere, Pakwan has quickly become a local favorite.</p>
         </div>
        </div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/>
        </div>
        <div>
        <p>As one of the best Indian restaurants in Colorado Springs, Pakwan offers a wide range of dishes that are sure to satisfy any craving. Whether you're a meat lover or a vegetarian, you'll find something on the menu that suits your taste buds. And with its commitment to using only the freshest and highest-quality ingredients, you can be sure that every dish is bursting with flavor.</p>
        </div>
        </div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>Pakwan Indian Restaurant is a family-owned and operated restaurant that has been serving the Colorado Springs community for over a decade. The owner, Mr. Singh, brings with him a wealth of experience and knowledge of Indian cuisine, having grown up in India and worked in the restaurant industry for many years. He brings his passion for food and dedication to quality to every dish served at Pakwan.</p></div></div>
        <div className="flex">
           
        <div className="mr-1">
        <GoPin/></div><div><p>The restaurant's menu features a range of dishes that are inspired by the diverse and flavorful cuisine of India. From classic curries to tandoori dishes, Pakwan has something for everyone. Their Tandoori Chicken is one of their most popular dishes. It's marinated in a special blend of spices and grilled in a tandoor oven, resulting in a juicy and flavorful dish that's hard to resist.</p></div></div>
        <div className="flex">
            
        <div className="mr-1">
        <GoPin/></div><div><p>Another popular dish on the menu is the Biryani. This aromatic dish is made with basmati rice, spices, and your choice of chicken, lamb, or vegetables. The rice is layered with the meat or vegetables and cooked slowly over a low flame, allowing the flavors to meld together perfectly. The result is a delicious and satisfying dish that's perfect for lunch or dinner.</p></div></div>
        <div className="flex">
           
        <div className="mr-1"><GoPin/></div><div><p>For those who prefer something creamier, the Paneer Makhani is a must-try. This dish features soft and crumbly paneer cheese, cooked in a creamy tomato sauce with a blend of spices. It's a rich and flavorful dish that's perfect for those who love a bit of indulgence in their meals.</p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>If you're looking for something spicier, try the Vindaloo. This fiery curry is not for the faint of heart, but for those who love a bit of heat in their food, it's a must-try. It's made with a blend of spices, vinegar, and your choice of meat or vegetables, resulting in a bold and flavorful dish that's sure to satisfy your cravings.
 </p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>For those with dietary restrictions, Pakwan offers plenty of options, including vegetarian, vegan, and gluten-free dishes. Their vegetarian options are some of the best in the city, with dishes like the Saag Paneer, a flavorful spinach-based dish with paneer cheese, and the Chana Masala, a hearty and satisfying dish made with chickpeas and a blend of spices.</p></div></div>
        <div className="flex">
        <div className="mr-1">
        <GoPin/></div><div><p>In addition to its extensive menu, Pakwan also offers a lunch buffet that's perfect if you're in a hurry or want to try a little bit of everything. The buffet includes a variety of vegetarian and non-vegetarian dishes, as well as rice, naan bread, and dessert. It's a great option for those who want to sample a range of flavors and dishes, and it's also a great value.</p></div></div>
        <div className="flex">
          
        <div className="mr-1">
        <GoPin/></div><div><p>If you're planning a party or event, Pakwan also offers catering services that are sure to impress your guests. Their catering menu includes a range of dishes that can be customized to meet your needs and preferences. Whether you're planning a corporate event or a family gathering, Pakwan's catering team will work with you to create a memorable and delicious experience for your guests.</p></div></div>
       
      </div>
      
   </>
  )
}
