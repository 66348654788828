import React from 'react'
import Iframe from "react-iframe";
import styled from "styled-components";

const MapStyle = styled.div`
  
  @media (max-width: 768px) {
    marginTop: 20vh;
  }
`;
export const Mapper = () => {
  return (
    <MapStyle>
    <div className='w-full h-screen' >
     <Iframe
              className="w-full h-full"
              height="750px"

                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d345.18199044640846!2d-104.75996503580895!3d38.89123865540813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87134f4911131645%3A0xf275fac93f01a857!2s3604%20Austin%20Bluffs%20Pkwy%2C%20Colorado%20Springs%2C%20CO%2080918%2C%20USA!5e0!3m2!1sen!2sin!4v1647123504824!5m2!1sen!2sin"
                style="border:'0'"
                allowfullscreen=""
                loading="lazy"
              ></Iframe>
    </div>
    </MapStyle>
  )
}
