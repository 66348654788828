import React, { useEffect, useState } from "react";
import food1 from "../../images/food1.jpg";
import "../../components/style.css";
import { useMediaQuery } from "../../hooks/MediaQueryHook";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import bannerImage from '../../images/new/main1.JPG';
import bannerImage2 from '../../images/new/snack.JPG';
import bar from '../../images/new/bar.JPG';



export const Product = () => {
  const [offsetY, setOffsetY] = useState(0);
  const isRowBased = useMediaQuery("(min-width: 500px)");

  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const content = {
    height: "auto",
    width: isRowBased ? "30vw" : "auto",
    padding: isRowBased ? "auto": "1.5rem",
    position: isRowBased ? "absolute" : "relative",
    /* right:'1rem',
    top:'1rem',
    width */
  };

  return (
    <div>
      <div className=" relative h-full ">
        <Slide bottom>
          <h3 className="text-center mb-2 mt-20 px-2">
            About Us
          </h3>
        </Slide>
        <Fade bottom>
          <h6 className="text-center">
            Fresh Ingredients, Tasty Food And Creative Chefs
          </h6>
        </Fade>
      <div className="py-10">
        <div className="lg:w-4/6 lg:pl-6 ">
          <Bounce left>
            <img 
              src={bannerImage}
              alt="indian food"
            />
            
          </Bounce>
        </div>
        <Bounce right>
          <div
            style={content}
            className="lg:top-48 lg:right-6 h-3/6 px-10 py-10 bg-white drop-shadow-md "
          >
            <h2 className="text-2xl">
              Experience the Indian food and culture
            </h2>
            <p className="text-left">
              Pakwan restaurant is an Indian restaurant located in the heart of
              Colorado. They are renown for their great food and reasonable
              prices. They have a huge selection of food ranging from vegetarian
              to non-vegetarian. The owners, Vipan Kumar and Balvir Singh. They can be seen at Pakwan
              himself every day greeting his customers and making sure that they
              are enjoying themselves. Pakwan is not just a restaurant but it is
              also an institution that has been making people happy through
              delicious food.</p>
          </div>
        </Bounce>
        </div>
      </div>
      <div className="py-10">
      <div className="lg:mt-0 relative h-full">
        <Bounce left>
          <div style={content} className="z-20 lg:top-24 lg:left-6 text-base font-mono left-0 h-3/6 bg-white px-14 py-10 drop-shadow-md">
            <h2 className="text-3xl">
              Delicious Food, Great Ambiance
            </h2>
            
            <p className=" text-left">
            We're proud to have the opportunity to share our culture and cuisine with you. The Menu:We've learned from the best Indian restaurants around the world and we're proud to now offer a curated menu of inventive dishes the likes of which has never been seen before. We've developed a menu so delicious that we're happy to share it with you! We’ll be offering special offers for the first time ever with our most popular dishes on offer every night of the week.
            </p>
          </div>
        </Bounce>
        
        <div className="flex z-10 ">
          <div className="lg:w-2/6">{/* svg */}</div>
          
          <div className="lg:w-4/6 lg:pr-6">
            <Bounce right>
            <img src={bannerImage2} alt="food image" />
            
            </Bounce>
          </div>
        
        </div>
      </div>
      </div>
      <div className="py-10 mt-16">
      <div className="mb-12 relative h-full">
        <Bounce left>
        <div className="lg:w-4/6 lg:pl-6 bannerImage">
          <img src={bar} alt="food"
          />
        </div>
        </Bounce>
        <Bounce right>
          <div style={content} className="lg:top-8 lg:right-6 px-14 py-10 right-0 h-3/6 bg-white drop-shadow-2xl">
          
            <h2 className="text-3xl">
              New Bar in town
            </h2>

            <p className="text-left">
            We are a new bar, located on the busy corner of Austin Bluffs. We have a well stocked bar, and we are always in the mood to party!. We offer a variety of beer, wine, spirits and food from our wine/beer fridge, plus we have a small wine/beer selection available upon request. Whether you are interested in wine and beer tasting, or something a little more exotic, our staff is always eager to help get you on the path to great conversation. We have a variety of drinks that are perfect for anyone. We cater for social events, weddings, and private parties. From our extensive repertoire of cocktails, wines and beers, to our friendly and knowledgeable staff, you can be sure you will have a great time. Our main goal is to make sure your event goes off without a hitch. It’s important to us that you feel welcome and at ease when you visit us.
            </p>
          </div>
        </Bounce>
      </div>
      </div>
    </div>
  );
};
