import React,{useState,useEffect} from 'react'
import styled from 'styled-components';
import Categories from './Categories';
import items from './Data'
import FoodMenu from './FoodMenu';
import './style.css';
import menu1 from '../../images/menu-1.png'
import menu2 from '../../images/menu-2.jpg'
import pdf from '../../images/Pakwan-Menu.pdf'


import { HiDownload } from "react-icons/hi";


const allCategories = ['all', ...new Set(items.map((item) => item.category))]


export const Menu = () => {

const [menuItems, setMenuItems] = useState(items)
  const [categories, setCategories] = useState(allCategories)

  const filterItems = (category) => {
    if (category === 'all') {
      setMenuItems(items)
      return
    }
    const newItems = items.filter((item) => item.category === category)
    setMenuItems(newItems)
  }
    
  return (
    <div>
      <main>
      
       <section className="menu">
        <div className="title display-flex">
        
        <div className='ml-24'>
        
          <h2 style={{fontSize:'1.65rem'}} className='md:text-base text-amber-50 text-left'>Our Menu</h2>
          <div className="underline mb-4"></div>
          </div> 
          <div className='mt-4 mr-6 '>
          <a className="" href={pdf} download> <HiDownload size="24px" color="white" /> </a>
          </div>
           
        </div>
        
        <div className='menu-image'>
        <img src={menu1} alt="Menu" style={{width:'100%'}}/>
        <img src={menu2} alt="Menu" style={{width:'100%'}}/>

        {/* <Categories categories={categories} filterItems={filterItems} />
        <FoodMenu items={menuItems} /> */}
        </div>
      </section> 
    </main>
    </div>
  )
}
