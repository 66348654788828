import React, { useEffect } from "react";
import { Banner } from "../../components/Banner";
import { Mapper } from "../../components/Map";
import Fade from 'react-reveal/Fade';
import { useMediaQuery } from "../../hooks/MediaQueryHook";

export const Contact = () => {
  const isRowBased = useMediaQuery("(min-width: 500px)");

  useEffect(()=>{
    window.onbeforeunload = () => {
      for(const form of document.getElementsByTagName('form')) {
        form.reset();
      }
    }
  },[])

  
  return (
    <>
      <Banner heading={'Contact Us'} />
      <Fade bottom big>

      <h3 className="text-center">Contact</h3>
      <h2 className="text-center mb-10">Contact For Any Query</h2>
      <h6></h6>
      </Fade>
      <div className="flex flex-wrap h-full mb-24">
        <div className={isRowBased?"flex-1 pl-20 flex flex-wrap pr-5 px-8 pt-2":"p-10"}>
          <p className="py-6 ">
          Pakwan restaurant is an Indian restaurant located in the heart of Colorado, the resturant was
            designed with the history in mind we have created a soft industrial
            dining room, combined with an open kitchen, coffee take out bar and
            on site coffee roastery.
          </p>
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h3 className="pb-4">Our Address</h3>
              <p>3604 Austin Bluffs Pkwy,
#140 Colorado Springs, CO 80918</p>
              <a target="_blank" href="https://www.google.com/maps/place/3604+Austin+Bluffs+Pkwy,+Colorado+Springs,+CO+80918,+USA/@38.891267,-104.759926,20z/data=!4m5!3m4!1s0x87134f4911131645:0xf275fac93f01a857!8m2!3d38.8912423!4d-104.7598877?hl=en">GET DIRECTIONS</a>
            </div>
            <div>
              <h3 className="pb-4">Our Phone</h3>
              <p>Office Telephone : + 1719-593-5005 Mobile : + 1719-593-5005</p>
              <a href="tel:8665562570">CALL TO US</a>
            </div>
            <div>
              <h3 className="pb-4">Our Email</h3>
              <p>Our Email : contact@pakwancos.com </p>
              <a href="mailto:name@7oroof@7oroof.com?subject=The%20subject%20of%20the%20mail">SEND AN EMAIL</a>
              
            </div>
            <div className="">
              <h3 className="pb-4">Follow Us</h3>
              <div className="flex">
                
              <a href="https://www.instagram.com/pakwan_indian_restaurant/" className="mr-6 text-gray-600">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                className="w-3.5"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
              <a href="https://www.facebook.com/people/Pakwan-Indian-Restaurant-Bar/100078764320301/" target="_blank">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-f"
                className="w-2.5 text-gray-600"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
              </a>
              </div>
            </div>
          </div>
        </div>
        <div className={isRowBased?"flex-1 pr-20 pl-5 px-8 pt -6":"p-10"}>
          <form
            action="https://formspree.io/f/mdovvnva"
            method="POST"
          >
            <div className="flex gap-x-4 my-4">
              <input
                type="text"
                className="w-2/4 bg-white-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="First Name"
                name="First Name"
                required
              />
              <input
                type="text"
                className="w-2/4 bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Last Name"
                name="Last Name"
                required
              />
            </div>
            <div className="flex gap-x-4 my-4">
              <input
                type="text"
                className="w-2/4 bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Email"
                name="email"
                required
              />
              <input
                type="number"
                className="w-2/4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Mobile"
                name="phone"
                required
              />
            </div>
            <textarea rows="4" cols="50"
              className="my-4 bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your Comment"
              name="comment"
              
            />
            <button
              type="submit"
              className="w-100 text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-purple-900"
            >
              SUBMIT
            </button>
          </form>
        </div>
        
        
      </div>
      <Mapper/>
    </>
  );
};
