import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { Home } from './pages/home/Home';
import { Menu } from './components/menu/Menu';
import { About } from './pages/about/About';
import styled from "styled-components";
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Contact } from './pages/contact/Contact';
import { Navbar } from './components/navbar';
import whatsapp from './images/whatsapp.png';
import { Special } from './pages/Special';
import { Gallery } from './pages/Gallery';
import { Link } from "react-router-dom";
import ScrollTop from './components/ScrollTop';
import Blogs from './pages/blogs/Blogs';
import { Blog1 } from './pages/blogs/Blog1';
import TagManager from 'react-gtm-module';
import { Blog2 } from './pages/blogs/blog2';
import { Blog3 } from './pages/blogs/Blog3';
import { Blog4 } from './pages/blogs/Blog4';


const Wrapper = styled.section`

overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}
`;



function App() {

  useEffect(() => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }}, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NL9SCWW' });
    }, []);

  
  
  
  return (
    

    <Wrapper>
      <>
      
      <Header />
      

      <Routes>  
        
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/menu" element={<Menu/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/speciality" element={<Menu/>} />
        <Route exact path="/gallery" element={<Gallery/>} />
        <Route exact path="/blog" element={<Blogs/>} />
        <Route exact path="/blog/1" element={<Blog1/>} />
        <Route exact path="/blog/2" element={<Blog2/>} />
        <Route exact path="/blog/3" element={<Blog3/>} />
        <Route exact path="/blog/4" element={<Blog4/>} />





        {/* <Route exact path="/test" element={<TutorialContainer/>} /> */}


      </Routes>
      <Footer/>
      {/* <div className='fixed bottom-2 right-2'>
        <a href="https://wa.me/18636241693" target="_blank"><img style={{width:'62px'}} src={whatsapp} alt="whatsapp icon" /></a>
      </div> */}
      </>
    </Wrapper>
    
  );
}

export default App;
