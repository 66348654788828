import React, { useEffect, useState } from "react";
import styled from "styled-components";
import reserve from "../../images/reserve.jpg";
import Pulse from "react-reveal/Pulse";
import food from "../../images/food.png";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import LightSpeed from "react-reveal/LightSpeed";
import DatePicker from "react-datepicker";
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';


const Content = styled.p`
  font-size: 14px;
  
`;
const ReserveWrapper = styled.div`

.imageSection1{
  
  
}
@media(max-width:700px){
.reserve_flexbox{
  display:block;
}
.imageSection1{
 width:100%;
 height:50%;
 img{
   height:50%;
 }
}
.imageSection2{
  width:100%;
}}

`;

export const Reserve = () => {
  const [value, onChange] = useState(new Date());

  
  const [date, setDate] = useState(false);
  
  const datePickerId = new Date().toISOString().split("T")[0];

  useEffect(()=>{
    window.onbeforeunload = () => {
      for(const form of document.getElementsByTagName('form')) {
        form.reset();
      }
    }
  },[])

  
  var today = new Date();
var dd = today.getDate();
var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
var yyyy = today.getFullYear();

if (dd < 10) {
    dd = '0' + dd
}

if (mm < 10) {
    mm = '0' + mm
}

today = yyyy + '-' + mm + '-' + dd;

  
  return (
    <div>
      <ReserveWrapper>
        <div className="flex reserve_flexbox drop-shadow-md md:mb-20">
          <div className="flex-1 w-3/6 imageSection1">
            <Pulse>
              <img style={{ objectFit: "cover" }} className="bg-cover h-full sm:h" src={reserve} alt="human using phone to make a reservation" />
            </Pulse>
          </div>
          <div className="px-20 py-20 w-3/6 bg-white py-10 imageSection2">
            <div className="flex-1 ">
              <Fade bottom>
                <h2>Book a table</h2>
              </Fade>
              <Fade bottom>
                <Content>Making a reservation at Délicious restaurant is easy and takes just a couple of minutes.</Content>
              </Fade>
            </div>
            <div>
              <form action="https://formspree.io/f/xjvddbpk" method="POST">
                <div className="flex gap-x-4 my-4">
                  <input type="text" className="w-full rounded-full bg-white border border-gray-400 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name*" name="Your Name" required />
                </div>

                <div className="flex gap-x-4 my-2">
                  {/* <input type="email" className="w-2/4 rounded-full border border-gray-400 bg-white pl-4 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:border-blue-500" placeholder="Email" name="Email" required /> */}
                  <input type="number" className="my-2 bg-white-50 rounded-full border border-gray-400 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:border-blue-500" placeholder="Mobile*" name="Mobile" required />  
                </div>
                <div className="lg:flex gap-x-4 my-2 ">
                
                <input type="number" className="lg:w-1/3 md:w-3/3 my-2 rounded-full border border-gray-400 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:border-blue-500" placeholder="No. of people*" name="No. of people" pattern="[1-9]{1}[0-9]{9}"  required />
                {/* <input type="date" className="w-1/4 my-2 bg-white-50 rounded-full border border-gray-400 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 focus:border-blue-500"placeholder="Date" min={datePickerId} /> */}
                
                {/* <DateTimePicker selected={date} onChange={onChange} value={value} /> */}
                <input type="date" id="Time" name="Time" placeholder="Time" className="lg:w-1/3 sm:w-3/3 my-2 rounded-full border border-gray-400 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:border-blue-500 md:w-4/4" min={today} required></input>
               
                <input type="time" id="Time" name="Time" placeholder="Time" className="lg:w-1/3 sm:w-3/3 my-2 rounded-full border border-gray-400 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:border-blue-500 md:w-4/4" required></input>

                </div>
                <textarea rows="2" cols="50" className="max-h-20 my-4 bg-white-50 rounded-full border border-gray-400 pl-6 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:border-blue-500" placeholder="Your Message" name="Message" />

                <button type="submit" className="w-full text-white border border-gray-400 bg-gradient-to-br from-orange-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:ring-orange-200 rounded-full dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </ReserveWrapper>
    </div>
  );
};
