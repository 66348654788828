import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";
import "../style.css";
import Fade from 'react-reveal/Fade';
import { useMediaQuery } from "../../hooks/MediaQueryHook";

export const Testimonial = () => {
  const isRowBased = useMediaQuery("(min-width: 500px)");

  const comments = {
    infinite: true,
    speed: 900,
    slidesToShow: isRowBased?2:1,
    slidesToScroll: 1,
    autoplay: true,
    //  
    autoplaySpeed: 5000,
    // centerMode: true,
    focusOnSelect: true,
    pauseOnHover: false,
    dots: true,

  };
  const Content_testimonial = styled.div `
    width: 50vw;
    textAlign: center;
    margin: auto;
    @media (max-width: 768px) {
    
    }
  `;
  
  return (
    <>
      <div className={isRowBased?"lg:mt-20 ":"mt-20 h-screen"}>
        <Content_testimonial>
        <Fade bottom>

          <h3 className="text-center">Testimonial</h3>
        </Fade>
        <Fade bottom>

        <h2 className="text-center">What our customer says</h2>
          </Fade>
        </Content_testimonial>
        <section className="testimonial_section flex flex-wrap lg:pt-16 md:pt-4 justify-center">
          <Slider className="w-screen container mx-auto" {...comments}>
          <div className={isRowBased?" max-w-2xl p-4 text-gray-800 border border-slate-200 h-52 bg-white rounded-lg  drop-shadow-lg test_card":"w-full p-8"}>
              <div className="mb-2">
                <div className="h-3 text-3xl text-left pb-8 lg:mt-0 md:mt-28">“</div>
                <p className=" text-center text-gray-600 md:px-10 md:text-justify md:px-10 md:text-justify">
                We are so fortunate to have this place just a few minutes drive away from home. Food is stunning, both the tapas and downstairs restaurant. Cocktails wow, wine great and lovely selection of beers. Love this place and will continue to visit.
                </p>
                <div className="h-3 text-3xl text-right">”</div>
                <div className="text-center">
                  <h6 className="font-bold">
                  Ethan Jones
                  </h6>
                </div>

                <div className="flex justify-center star_icon mt-1">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </div>
            </div>
            <div className={isRowBased?" max-w-2xl p-4 text-gray-800 border border-slate-200 h-52 bg-white rounded-lg  drop-shadow-lg test_card":"w-full p-8"}>
              <div className="mb-2">
                <div className="h-3 text-3xl text-left pb-8 lg:mt-0 lg:mt-0 md:mt-28">“</div>
                <p className=" text-center text-gray-600 md:px-10 md:text-justify">
                Delicious high quality plates, selection of wines and great service.
We tried all their entrees, one of their desserts, and everything was de-li-cious. Highly recommended.
                </p>
                <div className="h-3 text-3xl text-right">”</div>
                <div className="text-center">
                  <h6 className="font-bold">Mark Oliver</h6>
                  <div className="flex justify-center star_icon mt-1">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
              </div>
            </div>
            <div className={isRowBased?"p-4 text-gray-800 border border-slate-200 h-52 bg-white rounded-lg  drop-shadow-lg test_card":"w-full p-8"}>
              <div className="mb-2">
                <div className="h-3 text-3xl text-left pb-8 lg:mt-0 md:mt-28">“</div>
                <p className=" text-center text-gray-600 md:px-10 md:text-justify">
                Searching for places to eat in the local area and this place popped up. I don’t normally leave reviews but I feel compelled as the food was absolutely exquisite.
                </p>
                <div className="h-3 text-3xl text-right">”</div>
                <div className="text-center">
                  <h6 className="font-bold">Gurpreet Singh</h6>
                  <div className="flex justify-center star_icon mt-1">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
              </div>
            </div>
            <div className={isRowBased?" max-w-2xl p-4 text-gray-800 border border-slate-200 h-52 bg-white rounded-lg  drop-shadow-lg test_card":"w-full p-8"}>
              <div className="mb-2">
                <div className="h-3 text-3xl text-left pb-8 lg:mt-0 md:mt-28">“</div>
                <p className=" text-center text-gray-600 md:px-10 md:text-justify">
                Excellent food. Definitely fine dining. It's worth it and they do different deals on different nights so it’s worth checking them out before you book. Highly recommended.
                </p>
                <div className="h-3 text-3xl text-right">”</div>
                <div className="text-center">
                  <h6 className="font-bold">Matthew Brown</h6>
                  <div className="flex justify-center star_icon mt-1">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
              </div>
            </div>
           
            <div className={isRowBased?"p-4 text-gray-800 border border-slate-200 h-52 bg-white rounded-lg  drop-shadow-lg test_card":"w-full p-8"}>
              <div className="mb-2">
                <div className="h-3 text-3xl text-left pb-8 lg:mt-0 md:mt-28">“</div>
                <p className=" text-center text-gray-600 md:px-10 md:text-justify">
                It’s a great experience. The ambiance is very welcoming and charming. Amazing wines, food and service. Staff are extremely knowledgeable and make great recommendations.
                </p>
                <div className="h-3 text-3xl text-right">”</div>
                <div className="text-center">
                  <h6 className="font-bold">David Williams</h6>
                  <div className="flex justify-center star_icon mt-1">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className={isRowBased?" max-w-2xl p-4 text-gray-800 border border-slate-200 h-52 bg-white rounded-lg  drop-shadow-lg test_card":"w-full p-8"}>
              <div className="mb-2">
                <div className="h-3 text-3xl text-left pb-8 lg:mt-0 md:mt-28">“</div>
                <p className=" text-center text-gray-600 md:px-10 md:text-justify">
                Amaazing food! The whole experience from start to finish is great. The food can’t get better and the prices are fair for the portion size. Always a great spot to get great food.
                </p>
                <div className="h-3 text-3xl text-right">”</div>
                <div className="text-center">
                  <h6 className="font-bold ">Joseph Lewis</h6>
                  <div className="flex justify-center star_icon mt-1">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    
                  </div>
                </div>
              </div>
            </div>
            <div className={isRowBased?" max-w-2xl p-4 text-gray-800 border border-slate-200 h-52 bg-white rounded-lg  drop-shadow-lg test_card":"w-full p-8"}>
              <div className="mb-20">
                <div className="h-3 text-3xl text-left pb-8 lg:mt-0 md:mt-28">“</div>
                <p className=" text-center text-gray-600 md:px-10 md:text-justify">
                Absolutely amazing place to eat, we will be making a reservation for our next visit in colorado! 10 of 10 for every single aspect of this meal, and this is coming from people who know and love great food!
                </p>
                <div className="h-3 text-3xl text-right">”</div>
                <div className="text-center">
                  <h6 className="font-bold">Sandy</h6>
                  <div className="flex justify-center star_icon mt-1">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </section>
      </div>
    </>
  );
};
